$fa-font-path: "../font";
$html-font-size: 16;
$color-white: #fff;
$color-black: #000;
$color-red: #E3242B;
$color-green: #80FF40;


@font-face {
  font-family: 'Morning Memories Script';
  src: url('../font/MorningMemoriesScript.woff2') format('woff2'),
      url('../font/MorningMemoriesScript.woff') format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

$primary-font: 'basic-sans', sans-serif;
$script-font: 'Morning Memories Script', serif;