@import "variables";
@import "framework/framework";
@import "../../node_modules/@fortawesome/fontawesome-pro/scss/fontawesome";
@import "../../node_modules/@fortawesome/fontawesome-pro/scss/brands";

html {
	background: $color-black;
	font-family: $primary-font;
	font-size: 16px;
	line-height: 1;
	width: 100%;
	overflow-x: hidden;

	@include desktop-sm-down {
		font-size: 15px;
	}

	@include tablet-down {
		font-size: 14px;
	}

	@include phone-down {
		font-size: 13px;
	}

	@include phone-sm-down {
		font-size: 12px;
	}
}

body {
	color: $color-white;
	width: 100%;
	overflow-x: hidden;
	position: relative;
	min-height: 100vh;
	flex: 1;

	main {
		flex: 1;
	}
}

.glow {
	letter-spacing: -2px;
	color: #FFFFFF;
	text-shadow: 0px 0px 15px #FFFFFF8D;
	font-weight: 700;
}

a {
	color: inherit;

	@include hover-focus {
		color: $color-white;
	}
}

img.main-bg {
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	object-fit: cover;
	z-index: 1;
	pointer-events: none;
}

.main-wrapper {
	width: 100%;
	display: flex;
	flex-direction: column;
	min-height: 100vh;
	position: relative;
	z-index: 2;
}

header {
	min-height: 100vh;

	.container#mobile-nav-bg {
		padding-top: rems(40);
		transition: all .3s ease-in-out;

		@include phone-down {
			padding-bottom: rems(100);

			&.active {
				background-color: $color-black;
			}
		}
	}

	nav {
		display: flex;
		align-items: flex-start;
		justify-content: space-between;
		padding: rems(10) 0;

		@include phone-down {
			flex-direction: column;
			align-items: flex-start;
			padding: 0;
		}

		.logo-wrapper {
			flex: 1;

			h1#logo {
				margin: 0;

				a {
					img {
						max-width: rems(180);
					}
				}
			}
		}

		ul {
			list-style: none;
			display: flex;
			align-items: center;
			justify-content: center;
			padding: 0;
			margin: 0;

			li {
				a {}
			}
		}

		.mobile-layout-wrapper {
			flex: 3;
			width: 100%;
			display: flex;
			position: relative;
			transition: all .3s ease-in-out;

			@include phone-down {
				flex-direction: column;
				align-items: center;
				padding: 0;

				&:not(.active) {
					opacity: 0;
					pointer-events: none;
					height: 0;
				}
			}

			.menu-wrapper {
				flex: 2;

				@include phone-down {
					padding-bottom: rems(12);
					width: 100%;
				}

				ul#nav-menu {
					gap: rems(30);

					@include phone-down {
						flex-direction: column;
						align-items: center;
						padding-bottom: rems(20);
					}

					li {
						a {
							font-family: $script-font;
							font-size: rems(33);
							color: $color-green;
							text-transform: uppercase;
						}
					}
				}
			}

			.social-wrapper {
				flex: 1;
				padding-top: rems(8);

				ul.social {
					gap: rems(28);

					a {
						position: relative;

						&::after {
							content: '';
							position: absolute;
							top: 50%;
							left: 50%;
							transform: translate(-50%, -50%);
							border-radius: 50%;
							width: rems(33);
							height: rems(33);
							background-color: $color-red;
						}

						i {
							display: flex;
							justify-content: center;
							align-items: center;
							transition: all 0.3s ease; // Smooth transition for hover effect
							position: relative;
							z-index: 5;
							font-size: rems(18);

							&:hover {
								transform: scale(1.2); // Scale up the circle on hover
							}
						}
					}
				}
			}
		}

		.mobile-menu-wrapper {
			position: absolute;
			top: rems(40);
			right: rems(25);

			.mobile-menu-icon {
				display: none;
				flex-direction: column;
				justify-content: center;
				align-items: center;
				pointer-events: all;
				cursor: pointer;
				position: relative;
				z-index: 30;

				// ICON SIZE
				width: 30px;
				height: 40px;

				@include phone-down {
					display: flex;
				}
			}

			.line {
				width: 100%;
				height: 2px;
				background-color: $color-white;
				margin: 4.5px;
				transition: transform 0.3s ease;
				transform-origin: center;


				&.active {
					transform: rotate(45deg) !important;
					position: absolute;
					top: 30%;

					&.active:nth-child(2) {
						display: none;
					}

					&.active:nth-child(3) {
						transform: rotate(-45deg) !important;
					}
				}
			}
		}
	}
}

main {

	section {
		padding: rems(120) 0 rems(80);

		.container {
			position: relative;

			h2 {
				position: absolute;
				left: -20px;
				font-family: $script-font;
				font-size: rems(100);
				letter-spacing: -8px;
				text-transform: uppercase;
				color: $color-green;
				font-weight: 400;
				margin: 0;
				pointer-events: none;

				@media screen and (max-width: 1300px) {
					left: 10px;
				}

				&.tour {
					transform: rotate(10deg);
					top: -100px;
				}

				&.music {
					transform: rotate(-5deg);
					top: -5px;

					@include tablet-down {
						top: -80px;
					}
				}

				&.contact {
					transform: rotate(5deg);
					top: -80px;
				}
			}
		}
	}

	section#tour {
		.container {
			.bit-event {
				@include phone-down {
					flex-direction: column;
					align-items: flex-start;
				}
			}

			.bit-event-buttons {
				@include phone-down {
					margin: 0;
				}
			}
		}
	}


	section#music {
		.container {

			.grid-container {
				display: flex;
				flex-wrap: wrap;
				gap: rems(16);
				justify-content: space-between;
				margin-bottom: rems(40);

				.card {
					display: flex;
					flex-direction: column;
					align-items: flex-start;
					width: 30%;
					overflow: hidden;

					@include phone-down {
						width: 48%;
					}

					img {
						width: 100%;
						aspect-ratio: 1/1;
						object-fit: cover;
						margin-bottom: rems(4);
					}

					.card-title {
						font-size: rems(25);
					}
				}
			}

			.btn-wrapper {
				display: flex;
				justify-content: center;

				.all-music {
					color: $color-green;
					font-size: rems(34);
					font-weight: 700;
				}
			}
		}
	}
}

footer {
	padding: rems(100) 0 rems(200);

	.container {
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;

		.logo-wrapper {
			flex: 1;
			margin-bottom: rems(-40);

			h1#logo {
				margin: 0;

				a {
					img {
						max-width: rems(180);
					}
				}
			}
		}

		ul {
			list-style: none;
			display: flex;
			align-items: center;
			justify-content: center;
			padding: 0;
			margin: 0;

			li {
				a {}
			}
		}

		.menu-wrapper {
			flex: 2;
			padding-bottom: rems(24);

			ul#nav-menu {
				gap: rems(30);
				display: flex;
				justify-content: space-around;

				li {
					a {
						font-family: $script-font;
						font-size: rems(30);
						color: $color-green;
						text-transform: uppercase;
					}
				}
			}
		}

		.social-wrapper {
			flex: 1;
			padding-top: rems(8);

			ul.social {
				gap: rems(28);

				a {
					position: relative;

					&::after {
						content: '';
						position: absolute;
						top: 50%;
						left: 50%;
						transform: translate(-50%, -50%);
						border-radius: 50%;
						width: rems(33);
						height: rems(33);
						background-color: $color-red;
					}

					i {
						display: flex;
						justify-content: center;
						align-items: center;
						transition: all 0.3s ease; // Smooth transition for hover effect
						position: relative;
						z-index: 5;
						font-size: rems(18);

						&:hover {
							transform: scale(1.2); // Scale up the circle on hover
						}
					}
				}
			}
		}
	}
}